/*----- Core -----*/
import React from 'react';

import {
	StyledCard,
	CardTitle,
	TypeTitle,
	ContentInformation,
	StyledSeeDetails
} from './styles';
import SaleCard from '../SaleCard';
import { CardDescription } from '../SaleCard/styles';
import { capitalize } from '../../../utilities/utils';

const TransferSaleCard = ({
	title,
	description,
	validitiesFrom,
	validitiesTo,
	kind,
	category,
	details,
	...others
}) => {
	const typeDescription = `${kind.toUpperCase()} | ${capitalize(
		category.toLowerCase()
	)}`;

	return (
		<StyledCard>
			<SaleCard
				{...others}
				withImage={false}
				cardInformation={
					<ContentInformation>
						<TypeTitle content={typeDescription} size={3} />
						<CardTitle content={title} size={3} color={'secondary'} />

						<CardDescription>{description}</CardDescription>

						<StyledSeeDetails
							kind={typeDescription}
							title={title}
							details={details}
						/>
					</ContentInformation>
				}
			/>
		</StyledCard>
	);
};

export default TransferSaleCard;
